var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-container',{staticClass:"mt-4"},[_c('b-card',{attrs:{"header-class":"header"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('label',{staticClass:"mr-2 text-white",domProps:{"textContent":_vm._s('Yarışma Grubuna Katıl')}}),_c('b-button',{staticClass:"ml-4",attrs:{"to":{
          name: 'CompetitionDetail',
          params: { id: _vm.$route.params.id },
        },"variant":"primary"}},[_vm._v("Yarışma Detayları")])]},proxy:true}])},[_c('b-container',{staticClass:"mt-4"},[_c('b-row',[_c('b-col',[_c('b-form-input',{attrs:{"id":"groupCode","type":"text","required":"","placeholder":"Grup Kodu"},on:{"keyup":_vm.searchGroup},model:{value:(_vm.item.uid),callback:function ($$v) {_vm.$set(_vm.item, "uid", $$v)},expression:"item.uid"}}),_c('b-button',{staticClass:"mt-4",attrs:{"variant":"primary"},on:{"click":_vm.searchGroup}},[_vm._v("Ara")])],1)],1)],1),(_vm.singleIndividualGroup && _vm.singleIndividualGroup.id)?_c('div',[_c('b-container',{staticClass:"mt-4"},[(_vm.singleIndividualGroup.personel1)?_c('b-row',[_c('b-col',{staticClass:"mt-4 text-danger font-weight-bold"},[_vm._v("1. Sıradaki Oyuncu (Takım Kaptanı) :")]),_c('b-col',{staticClass:"mt-4 font-weight-bold"},[_c('b-row',[_c('b-col',[_c('router-link',{attrs:{"to":{
                      name: 'PersonelDetail',
                      params: { id: _vm.singleIndividualGroup.personel1.id },
                    }},domProps:{"textContent":_vm._s(
                      _vm.singleIndividualGroup.personel1.name +
                      ' ' +
                      _vm.singleIndividualGroup.personel1.surname
                    )}})],1)],1)],1)],1):_vm._e(),(_vm.singleIndividualGroup.personel2 && _vm.checkBoatType(2))?_c('b-row',[_c('b-col',{staticClass:"mt-4 text-danger font-weight-bold"},[_vm._v("2. Sıradaki Oyuncu :")]),_c('b-col',{staticClass:"mt-4 font-weight-bold"},[_c('router-link',{attrs:{"to":{
                  name: 'PersonelDetail',
                  params: { id: _vm.singleIndividualGroup.personel2.id },
                }},domProps:{"textContent":_vm._s(
                  _vm.singleIndividualGroup.personel2.name +
                  ' ' +
                  _vm.singleIndividualGroup.personel2.surname
                )}})],1)],1):_vm._e(),(_vm.singleIndividualGroup.personel3 && _vm.checkBoatType(3))?_c('b-row',[_c('b-col',{staticClass:"mt-4 text-danger font-weight-bold"},[_vm._v("3. Sıradaki Oyuncu :")]),_c('b-col',{staticClass:"mt-4 font-weight-bold"},[_c('router-link',{attrs:{"to":{
                  name: 'PersonelDetail',
                  params: { id: _vm.singleIndividualGroup.personel3.id },
                }},domProps:{"textContent":_vm._s(
                  _vm.singleIndividualGroup.personel3.name +
                  ' ' +
                  _vm.singleIndividualGroup.personel3.surname
                )}})],1)],1):_vm._e(),(_vm.singleIndividualGroup.personel4 && _vm.checkBoatType(4))?_c('b-row',[_c('b-col',{staticClass:"mt-4 text-danger font-weight-bold"},[_vm._v("4. Sıradaki Oyuncu :")]),_c('b-col',{staticClass:"mt-4 font-weight-bold"},[_c('router-link',{attrs:{"to":{
                  name: 'PersonelDetail',
                  params: { id: _vm.singleIndividualGroup.personel4.id },
                }},domProps:{"textContent":_vm._s(
                  _vm.singleIndividualGroup.personel4.name +
                  ' ' +
                  _vm.singleIndividualGroup.personel4.surname
                )}})],1)],1):_vm._e(),(_vm.singleIndividualGroup.personelBackup && _vm.checkBoatType(5))?_c('b-row',[_c('b-col',{staticClass:"mt-4 text-danger font-weight-bold"},[_vm._v("Yedek Oyuncu :")]),_c('b-col',{staticClass:"mt-4 font-weight-bold"},[_c('router-link',{attrs:{"to":{
                  name: 'PersonelDetail',
                  params: { id: _vm.singleIndividualGroup.personelBackup.id },
                }},domProps:{"textContent":_vm._s(
                  _vm.singleIndividualGroup.personelBackup.name +
                  ' ' +
                  _vm.singleIndividualGroup.personelBackup.surname
                )}})],1)],1):_vm._e(),(
              _vm.singleIndividualGroup.personelBackup2 &&
              _vm.checkBoatType(5) &&
              _vm.checkGender()
            )?_c('b-row',[_c('b-col',{staticClass:"mt-4 text-danger font-weight-bold"},[_vm._v("Yedek Oyuncu 2 :")]),_c('b-col',{staticClass:"mt-4 font-weight-bold"},[_c('router-link',{attrs:{"to":{
                  name: 'PersonelDetail',
                  params: { id: _vm.singleIndividualGroup.personelBackup2.id },
                }},domProps:{"textContent":_vm._s(
                  _vm.singleIndividualGroup.personelBackup2.name +
                  ' ' +
                  _vm.singleIndividualGroup.personelBackup2.surname
                )}})],1)],1):_vm._e(),_c('b-row',[_c('b-col',{staticClass:"mt-4 text-danger font-weight-bold"},[_vm._v(" Kategori : ")]),_c('b-col',{staticClass:"mt-4 font-weight-bold"},[_vm._v(_vm._s(_vm.getCategory(_vm.singleIndividualGroup.category)))])],1),_c('b-row',[_c('b-col',{staticClass:"mt-4 text-danger font-weight-bold"},[_vm._v("Tekne Sınıfı:")]),_c('b-col',{staticClass:"mt-4 font-weight-bold"},[_vm._v(_vm._s(_vm.getBoatClass(_vm.singleIndividualGroup.boatType)))])],1),_c('b-row',[_c('b-col',{staticClass:"mt-4 text-danger font-weight-bold"},[_vm._v("Mesafe :")]),_c('b-col',{staticClass:"mt-4 font-weight-bold"},[_vm._v(_vm._s(_vm.getDistance(_vm.singleIndividualGroup.distance)))])],1),_c('b-row',{staticClass:"mt-4",staticStyle:{"padding-top":"1rem"}},[_c('b-col',[(_vm.hasEmptyPersonel() && _vm.personelIsNotInBoat())?_c('b-button',{attrs:{"block":"","variant":"primary"},on:{"click":function($event){return _vm.joinAsPersonel()}}},[_vm._v("As oyuncu olarak katıl")]):_vm._e()],1),_c('b-col',[(_vm.hasEmptyBackupPersonel() && _vm.personelIsNotInBoat())?_c('b-button',{attrs:{"block":"","variant":"dark"},on:{"click":function($event){return _vm.joinAsBackupPersonel()}}},[_vm._v("Yedek oyuncu olarak katıl")]):_vm._e()],1)],1)],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }