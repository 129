<template>
  <div>
    <b-container class="mt-4">
      <b-card header-class="header">
        <template #header>
          <label
            v-text="'Yarışma Grubuna Katıl'"
            class="mr-2 text-white"
          ></label>

          <b-button class="ml-4" :to="{
            name: 'CompetitionDetail',
            params: { id: $route.params.id },
          }" variant="primary">Yarışma Detayları</b-button>
        </template>

        <b-container class="mt-4">
          <b-row>
            <b-col>
              <b-form-input
                id="groupCode"
                v-model="item.uid"
                type="text"
                @keyup="searchGroup"
                required
                placeholder="Grup Kodu"
              ></b-form-input>
              <b-button variant="primary" class="mt-4" @click="searchGroup"
                >Ara</b-button
              >
            </b-col>
          </b-row>
        </b-container>
        <div v-if="singleIndividualGroup && singleIndividualGroup.id">
          <b-container class="mt-4">
            <b-row v-if="singleIndividualGroup.personel1">
              <b-col class="mt-4 text-danger font-weight-bold"
                >1. Sıradaki Oyuncu (Takım Kaptanı) :</b-col
              >
              <b-col class="mt-4 font-weight-bold">
                <b-row>
                  <b-col>
                    <router-link
                      v-text="
                        singleIndividualGroup.personel1.name +
                        ' ' +
                        singleIndividualGroup.personel1.surname
                      "
                      :to="{
                        name: 'PersonelDetail',
                        params: { id: singleIndividualGroup.personel1.id },
                      }"
                    ></router-link
                  ></b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row v-if="singleIndividualGroup.personel2 && checkBoatType(2)">
              <b-col class="mt-4 text-danger font-weight-bold"
                >2. Sıradaki Oyuncu :</b-col
              >
              <b-col class="mt-4 font-weight-bold">
                <router-link
                  v-text="
                    singleIndividualGroup.personel2.name +
                    ' ' +
                    singleIndividualGroup.personel2.surname
                  "
                  :to="{
                    name: 'PersonelDetail',
                    params: { id: singleIndividualGroup.personel2.id },
                  }"
                ></router-link>
              </b-col>
            </b-row>
            <b-row v-if="singleIndividualGroup.personel3 && checkBoatType(3)">
              <b-col class="mt-4 text-danger font-weight-bold"
                >3. Sıradaki Oyuncu :</b-col
              >
              <b-col class="mt-4 font-weight-bold">
                <router-link
                  v-text="
                    singleIndividualGroup.personel3.name +
                    ' ' +
                    singleIndividualGroup.personel3.surname
                  "
                  :to="{
                    name: 'PersonelDetail',
                    params: { id: singleIndividualGroup.personel3.id },
                  }"
                ></router-link>
              </b-col>
            </b-row>
            <b-row v-if="singleIndividualGroup.personel4 && checkBoatType(4)">
              <b-col class="mt-4 text-danger font-weight-bold"
                >4. Sıradaki Oyuncu :</b-col
              >
              <b-col class="mt-4 font-weight-bold">
                <router-link
                  v-text="
                    singleIndividualGroup.personel4.name +
                    ' ' +
                    singleIndividualGroup.personel4.surname
                  "
                  :to="{
                    name: 'PersonelDetail',
                    params: { id: singleIndividualGroup.personel4.id },
                  }"
                ></router-link>
              </b-col>
            </b-row>
            <b-row
              v-if="singleIndividualGroup.personelBackup && checkBoatType(5)"
            >
              <b-col class="mt-4 text-danger font-weight-bold"
                >Yedek Oyuncu :</b-col
              >
              <b-col class="mt-4 font-weight-bold">
                <router-link
                  v-text="
                    singleIndividualGroup.personelBackup.name +
                    ' ' +
                    singleIndividualGroup.personelBackup.surname
                  "
                  :to="{
                    name: 'PersonelDetail',
                    params: { id: singleIndividualGroup.personelBackup.id },
                  }"
                ></router-link>
              </b-col>
            </b-row>
            <b-row
              v-if="
                singleIndividualGroup.personelBackup2 &&
                checkBoatType(5) &&
                checkGender()
              "
            >
              <b-col class="mt-4 text-danger font-weight-bold"
                >Yedek Oyuncu 2 :</b-col
              >
              <b-col class="mt-4 font-weight-bold">
                <router-link
                  v-text="
                    singleIndividualGroup.personelBackup2.name +
                    ' ' +
                    singleIndividualGroup.personelBackup2.surname
                  "
                  :to="{
                    name: 'PersonelDetail',
                    params: { id: singleIndividualGroup.personelBackup2.id },
                  }"
                ></router-link>
              </b-col>
            </b-row>

            <!-- <b-row v-if="form.personelBackup" :key="form.personelBackup.id">
                <b-col class="mt-4 text-danger font-weight-bold">
                  Yedek Katılımcının Adı Soyadı :
                </b-col>
                <b-col class="mt-4 font-weight-bold">
                  <router-link
                    v-text="
                      form.personelBackup.name +
                      ' ' +
                      form.personelBackup.surname
                    "
                    :to="{
                      name: 'PersonelDetail',
                      params: { id: form.personelBackup.id },
                    }"
                  ></router-link>
                </b-col>
              </b-row>
              <b-row v-if="form.personelBackup2" :key="form.personelBackup2.id">
                <b-col class="mt-4 text-danger font-weight-bold">
                  Yedek Katılımcının Adı Soyadı :
                </b-col>
                <b-col class="mt-4 font-weight-bold">
                  <router-link
                    v-text="
                      form.personelBackup2.name +
                      ' ' +
                      form.personelBackup2.surname
                    "
                    :to="{
                      name: 'PersonelDetail',
                      params: { id: form.personelBackup2.id },
                    }"
                  ></router-link>
                </b-col>
              </b-row> -->

            <b-row>
              <b-col class="mt-4 text-danger font-weight-bold">
                Kategori :
              </b-col>
              <b-col class="mt-4 font-weight-bold">{{
                getCategory(singleIndividualGroup.category)
              }}</b-col>
            </b-row>

            <b-row>
              <b-col class="mt-4 text-danger font-weight-bold"
                >Tekne Sınıfı:</b-col
              >
              <b-col class="mt-4 font-weight-bold">{{
                getBoatClass(singleIndividualGroup.boatType)
              }}</b-col>
            </b-row>
            <b-row>
              <b-col class="mt-4 text-danger font-weight-bold">Mesafe :</b-col>
              <b-col class="mt-4 font-weight-bold">{{
                getDistance(singleIndividualGroup.distance)
              }}</b-col>
            </b-row>

            <b-row class="mt-4" style="padding-top: 1rem">
              <b-col>
                <b-button
                  v-if="hasEmptyPersonel() && personelIsNotInBoat()"
                  block
                  variant="primary"
                  @click="joinAsPersonel()"
                  >As oyuncu olarak katıl</b-button
                >
              </b-col>
              <b-col>
                <b-button
                  v-if="hasEmptyBackupPersonel() && personelIsNotInBoat()"
                  block
                  variant="dark"
                  @click="joinAsBackupPersonel()"
                  >Yedek oyuncu olarak katıl</b-button
                >
              </b-col>
            </b-row>
          </b-container>
        </div>
      </b-card>
    </b-container>
  </div>
</template>
  
  <script>
import { mapGetters } from "vuex";
import { genderOptions } from "@/store/constants/personel";

export default {
  data() {
    return {
      item: {
        uid: "",
      },
      individualGroup: {},
    };
  },
  computed: {
    ...mapGetters(["getSingleIndividualGroup", "getManagementItems"]),
    singleIndividualGroup() {
      return this.getSingleIndividualGroup;
    },
    getGenderOptions: {
      get() {
        return genderOptions.concat([{ text: "Mix", value: "mixed" }]);
      },
    },
  },
  created() {
    this.$store.dispatch("initManagement");
  },
  destroyed() {
    this.$store.commit("updateSingleIndividualGroup", {});
  },

  methods: {
    async searchGroup() {
      if (this.item.uid.trim() == "") return;
      if (this.item.uid.trim().length < 6) return;

      await this.$store.dispatch("getIndividualGroupByGroupCode", {
        uid: this.item.uid.trim(),
        competitionId: this.$route.params.id,
      });
    },
    userIsTheAdmin() {
      return (
        this.$store.getters.getUser.id ==
        this.singleIndividualGroup.personel1.id
      );
    },
    checkBoatType(index) {
      return (
        this.getManagementItems?.find(
          (x) => x.id == this.getSingleIndividualGroup.boatType
        ).option >= index
      );
    },
    getCategory(id) {
      return this.getManagementItems?.find((x) => x.id == id).title;
    },
    getBoatClass(id) {
      return this.getManagementItems?.find((x) => x.id == id).title;
    },
    getDistance(id) {
      return this.getManagementItems?.find((x) => x.id == id).title
        ? this.getManagementItems?.find((x) => x.id == id).title + " metre"
        : "";
    },
    checkGender() {
      console.log(this.getSingleIndividualGroup);
      return this.getSingleIndividualGroup?.gender == "mixed";
    },
    hasEmptyPersonel() {
      const maxPersonel = this.getManagementItems?.find(
        (x) => x.id == this.getSingleIndividualGroup.boatType
      ).option;
      if (maxPersonel == 1) return false;
      else {
        if (maxPersonel == 2) {
          if (
            this.getSingleIndividualGroup.personel2 != null &&
            this.getSingleIndividualGroup.personel1 != null
          )
            return false;
          else return true;
        } else if (maxPersonel == 3) {
          if (
            this.getSingleIndividualGroup.personel2 != null &&
            this.getSingleIndividualGroup.personel1 != null &&
            this.getSingleIndividualGroup.personel3 != null
          )
            return false;
          else return true;
        } else if (maxPersonel >= 4) {
          if (
            this.getSingleIndividualGroup.personel2 != null &&
            this.getSingleIndividualGroup.personel1 != null &&
            this.getSingleIndividualGroup.personel3 != null &&
            this.getSingleIndividualGroup.personel4 != null
          )
            return false;
          else return true;
        }
      }
    },
    hasEmptyBackupPersonel() {
      const maxPersonel = this.getManagementItems?.find(
        (x) => x.id == this.getSingleIndividualGroup.boatType
      ).option;
      if (maxPersonel > 4) {
        if (this.getSingleIndividualGroup.gender == "mixed") {
          if (
            this.getSingleIndividualGroup.personelBackup != null &&
            this.getSingleIndividualGroup.personelBackup2 != null
          )
            return false;
          else return true;
        } else {
          if (this.getSingleIndividualGroup.personelBackup != null)
            return false;
          else return true;
        }
      } else return false;
    },
    async joinAsPersonel() {
      this.$store
        .dispatch("joinIndividualGroup", {
          id: this.getSingleIndividualGroup.id,
          personelId: this.$store.getters.getUser.id,
          uid: this.getSingleIndividualGroup.uid,
          competitionId: this.$route.params.id,
          position: this.getPosition(this.getSingleIndividualGroup),
        })
        .then(() => {
          this.$router.replace({
            name: "IndividualCompetitionGroupsList",
            params: { id: this.$route.params.id },
          });
          this.$store.dispatch("notification/setNotifications", [
            {
              title: "Gruba katıldınız!",
              body: "Gruba başarıyla katıldınız",
              type: "success",
            },
          ]);
        });
    },
    getPosition(group) {
      if (group.personel1 == null) return "personelId1";
      else if (group.personel2 == null) return "personelId2";
      else if (group.personel3 == null) return "personelId3";
      else if (group.personel4 == null) return "personelId4";
    },
    getBackupPosition(group) {
      if (group.personelBackup == null) return "personelIdBackup";
      else if (group.personelBackup2 == null) return "personelIdBackup2";
    },
    personelIsNotInBoat() {
      if (
        this.getSingleIndividualGroup.personel1?.id ==
        this.$store.getters.getUser.id
      )
        return false;
      else if (
        this.getSingleIndividualGroup.personel2?.id ==
        this.$store.getters.getUser.id
      )
        return false;
      else if (
        this.getSingleIndividualGroup.personel3?.id ==
        this.$store.getters.getUser.id
      )
        return false;
      else if (
        this.getSingleIndividualGroup.personel4?.id ==
        this.$store.getters.getUser.id
      )
        return false;
      else if (
        this.getSingleIndividualGroup.personelBackup?.id ==
        this.$store.getters.getUser.id
      )
        return false;
      else if (
        this.getSingleIndividualGroup.personelBackup2?.id ==
        this.$store.getters.getUser.id
      )
        return false;
      else return true;
    },
    async joinAsBackupPersonel() {
      this.$store
        .dispatch("joinIndividualGroup", {
          id: this.getSingleIndividualGroup.id,
          personelId: this.$store.getters.getUser.id,
          competitionId: this.$route.params.id,
          uid: this.getSingleIndividualGroup.uid,
          position: this.getBackupPosition(this.getSingleIndividualGroup),
        })
        .then(() => {
          this.$router.replace({
            name: "IndividualCompetitionGroupsList",
            params: { id: this.getSingleIndividualGroup.id },
          });
          this.$store.dispatch("notification/setNotifications", [
            {
              title: "Gruba katıldınız!",
              body: "Gruba başarıyla katıldınız",
              type: "success",
            },
          ]);
        });
    },
  },
};
</script>
  
  <style scoped>
.header {
  background: rgb(108, 117, 125);
  background: linear-gradient(
    180deg,
    rgb(108, 117, 125) 10%,
    rgba(53, 57, 61, 1) 85%
  );
}
</style>